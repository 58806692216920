/** @jsxImportSource theme-ui */

import React, { createContext, useState, useEffect, useContext } from 'react'
import { Close, Text, Box, Button, Flex, Heading, Spinner } from 'theme-ui'
import { BrowserView, MobileView } from 'react-device-detect'
import { Menu24, OpenPanelLeft16 } from '@carbon/icons-react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { useMemo } from 'react'
import '@reach/dialog/styles.css'

export function AppLoading() {
  return (
    <Flex sx={{ minHeight: '100vh', justifyContent: 'center', alignItems: 'center', }} >
      <Spinner />
    </Flex>
  )
}

export const Container = ({
  children,
  gridArea,
  bg = 'background',
  position = 'static',
}) => (
  <Box
    sx={{ gridArea, px: [4, 6], py: [4, 5], bg, maxWidth: '80ch', position }}
  >
    {children}
  </Box>
)

export const Loading = () => (
  <Box
    sx={{ position: 'absolute', left: '50%', top: '50%', marginLeft: '-12px' }}
  >
    <Spinner size={24} />
  </Box>
)

export function LoadingView() {
  return (
    <View>
      <Container>
        <Heading as="h1" />
      </Container>
      <Container position="relative">
        <Loading />
      </Container>
      <Container />
    </View>
  )
}

export const View = ({ children, isFetching = false, ...props }) => (
  <Box
    __css={{
      minHeight: '100vh',
      display: 'grid',
      gap: 2,
      opacity: isFetching ? 0.6 : 1,
      gridTemplateRows: 'auto 1fr auto',
      gridTemplateAreas: '"header" "main" "footer"',
    }}
    {...props}
  >
    {children}
  </Box>
)

export const Root = ({ children }) => {
  return (
    <Flex
      variant="layout.root"
      sx={{ minHeight: '100vh', flexDirection: 'column' }}
    >
      <Box sx={{ minHeight: '100vh' }}>{children}</Box>
    </Flex>
  )
}

export function Content({ children }) {
  const { sidebarWidth, sidebarOpen } = useContext(LayoutContext)

  return (
    <>
      <BrowserView>
        <Box
          sx={{
            bg: 'gray.0',
            position: 'relative',
            transition: 'margin 0.45s ease-in-out',
            marginRight: sidebarOpen ? [`calc(-1 * ${sidebarWidth})`, 0] : 0,
            marginLeft: sidebarOpen ? sidebarWidth : 0,
          }}
        >
          {children}
        </Box>
      </BrowserView>
      <MobileView>
        <Box
          sx={{
            bg: 'gray.0',
            paddingBottom: 96,
          }}
        >
          {children}
        </Box>
      </MobileView>
    </>
  )
}

export function Sidebar(props) {
  const { sidebarWidth } = useContext(LayoutContext)

  return (
    <Box sx={{ gridArea: 'sidebar', position: 'relative' }}>
      <Flex
        sx={{
          p: 4,
          top: 0,
          bottom: 0,
          position: 'fixed',
          transform: 'translate3d(0,0,0)',
          overflowY: 'auto',
          width: sidebarWidth,
          flexDirection: 'column',
        }}
        {...props}
      />
    </Box>
  )
}

export const SidebarButton = () => {
  const { toggleSidebar, sidebarOpen } = useContext(LayoutContext)

  return (
    <BrowserView>
      <Button
        onClick={toggleSidebar}
        variant="bare"
        sx={{
          paddingX: 0,
          fontSize: 0,
          color: 'muted',
          '& > svg': {
            verticalAlign: 'middle',
            marginRight: 2,
            color: 'primary',
          },
        }}
      >
        <OpenPanelLeft16 />
        <Text>
          {sidebarOpen ? (
            "Menü einklappen"
          ) : (
            "Menü ausklappen"
          )}
        </Text>
      </Button>
    </BrowserView>
  )
}

export const LayoutContext = createContext()

function getStateFromSession(defaultState) {
  if (typeof window === 'undefined') return
  const stateAsJSON = window.sessionStorage.getItem('layout')
  if (stateAsJSON !== null) {
    const state = JSON.parse(stateAsJSON)
    return state
  } else {
    return defaultState
  }
}

function persistStateToSession(state) {
  if (typeof window === 'undefined') return
  window.sessionStorage.setItem('layout', JSON.stringify(state))
}

export function Layout({ children, sidebarWidth = '248px' }) {
  const [state, setState] = useState(getStateFromSession({ sidebarOpen: true }))

  const sidebarOpen = state?.sidebarOpen

  const context = useMemo(
    () => ({
      toggleSidebar: () =>
        setState((state) => {
          return { ...state, sidebarOpen: !state.sidebarOpen }
        }),
      sidebarWidth,
      sidebarOpen,
    }),
    [sidebarWidth, setState, sidebarOpen]
  )

  useEffect(() => {
    persistStateToSession({ sidebarOpen })
  }, [sidebarOpen])

  return (
    <LayoutContext.Provider value={context}>{children}</LayoutContext.Provider>
  )
}

export const Menu = ({ children }) => {
  const [showMenu, setShowMenu] = React.useState(false)
  const open = () => setShowMenu(true)
  const close = () => setShowMenu(false)

  return (
    <>
      <Box
        data-mobile-menu
        sx={{
          position: 'fixed',
          transform: 'translate3d(0,0,0)',
          zIndex: 100,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Flex
          sx={{
            mb: 3,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}
        >
          <MenuButton onClick={open} />
        </Flex>

        <DialogOverlay
          sx={{ background: 'hsla(0, 100%, 100%, 0.5) !important', zIndex: 1000 }}
          isOpen={showMenu}
          onDismiss={close}
        >
          <DialogContent
            aria-label="Menu"
            sx={{
              '&[data-reach-dialog-content]': {
                position: 'absolute',
                color: 'white',
                width: '100%',
                left: 0,
                right: 0,
                bottom: 0,
                bg: 'primary',
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                margin: 0,
                paddingX: 3,
                paddingTop: 1,
                paddingBottom: 3,
                '[data-nav-item] > a': {
                  paddingY: 1,
                },
              }
            }}
          >
            <Close
              onClick={close}
              sx={{ width: '100%', bg: 'primary', color: 'white' }}
            />
            {children}
          </DialogContent>
        </DialogOverlay>
      </Box>
    </>
  )
}

const MenuButton = React.forwardRef((props, ref) => (
  <Box
    as="button"
    ref={ref}
    sx={{
      border: 0,
      appearance: 'none',
      fontSize: 'inherit',
      cursor: 'pointer',
      position: 'relative',
      bg: 'primary',
      width: '3.5em',
      height: '3.5em',
      borderRadius: '99em',
      pointerEvents: 'all',
      boxShadow:
        'inset 0 0 0px 1px hsla(0, 0%, 100%, 0.25), 0 1px 18px 4px hsla(0, 0%, 0%, 0.4)',
      '&:focus, &:hover': {
        outline: 'none',
        bg: 'primaries.4',
      },
    }}
    {...props}
  >
    <Box
      sx={{
        top: '50%',
        left: '50%',
        marginLeft: '-12px',
        marginTop: '-12px',
        position: 'absolute',
        svg: { color: 'white' },
      }}
    >
      <Menu24 />
    </Box>
  </Box>
))

export const NavItem = ({ type = 'primary', children }) => (
  <Box
    data-nav-item
    sx={{
      '> a': {
        display: 'block',
        py: '2px',
        color: 'inherit',
        textDecoration: 'none',
        fontWeight: type === 'primary' ? 500 : 300,
        position: 'relative',
        cursor: 'pointer',
        '& svg': {
          color: 'hsla(0,0%,0%,0.5)',
        },
        '&[aria-current="page"], &[data-active=true]': {
          '&:before': {
            content: '""',
            top: 0,
            bottom: 0,
            left: -4,
            width: '4px',
            bg: 'primary',
            position: 'absolute',
          },
        },
        '&:hover': {
          '&:after': {
            content: '""',
            top: 0,
            bottom: 0,
            bg: 'hsla(0,0%,0%, 0.025)',
            position: 'absolute',
            left: -4,
            right: -4,
          },
        },
      },
    }}
  >
    {children}
  </Box>
)

